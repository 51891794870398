import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "checked",
        "total"
    ]

    connect() {
        console.log("Backorder Count controller connected")
    }

    toggle(event) {
        console.log("Counting backorder items.")

        let checked = 0
        this.checkedTargets.forEach((el, i) => {
            console.log("Backorder check box: ", el)
            if (el.checked) {
                console.log("Check box is checked")
                checked += 1
            }
        })

        console.log("Backorder count", checked)
        this.totalTarget.value = checked
    }
}
