import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['unfulfilledLineItems']

    refreshUnfulfilledLineItems() {
        fetch(this.unfulfilledLineItemsTarget.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                this.unfulfilledLineItemsTarget.innerHTML = html
            })
    }

    refresh() {
        const containerTarget = document.getElementById('fulfillmentsContainer')
        fetch(containerTarget.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                const containerTarget = document.getElementById('fulfillmentsContainer')
                containerTarget.innerHTML = html
            })
    }
}
