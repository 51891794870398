import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "file",
        "wrapper"
    ]

    connect() {
        console.debug("Admin ExtraDocuments controller connected");
    }

    upload() {
        // console.log("Uploading file")

        let reader = new FileReader()

        reader.addEventListener("load", () => {
            console.log("File field done loading file")
            this.transmitFile(reader.result, this.fileTarget.files[0].name)
        }, false)

        reader.readAsDataURL(this.fileTarget.files[0])
    }

    transmitFile(file, filename) {
        // console.log("Transmitting file")

        let url = this.data.get("url")
        let data = {file: file, filename: filename}

        fetch(url, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.text())
            .then(text => {
                // If the upload failed, show the user why:
                this.element.innerHTML = text;
            })
    }
}
