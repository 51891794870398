import {Controller} from "@hotwired/stimulus"
// import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ['content', 'lineItem', 'warehouse']

    get orderController() {
        let orderElement = document.getElementById(`order_${this.data.get("order-id")}`)
        return this.application.getControllerForElementAndIdentifier(orderElement, "order")
    }

    connect() {
        console.log("Return Fulfillment controller connected")
    }

    warehouseSelected(e) {
        const warehouseId = e.currentTarget.value

        this.warehouseTargets.forEach(target => {
            if (target.dataset.warehouseId == warehouseId) {
                target.classList.add('warehouse-selected')
            } else {
                target.classList.remove('warehouse-selected')
            }
        })

        this.lineItemTargets.forEach(target => {
            this.application.getControllerForElementAndIdentifier(target, 'return-fulfillment--line-item').warehouseSelected(warehouseId)
        })
    }

    fulfillmentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.element.outerHTML = html
            })
    }

    contentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.contentTarget.innerHTML = html
            })
    }

    cancelFulfillment(e) {
        this.submitRequest(e)
            .then(html => {
                this.refreshFulfillments()
                this.orderController.refreshDetails(html)
            })
    }

    refreshFulfillments() {
        const event = document.createEvent('CustomEvent')
        event.initCustomEvent('return-fulfillment-transition-completed', true, true, null)
        this.element.dispatchEvent(event)
    }

    submitRequest(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        return fetch(e.currentTarget.href, {
            method: method.toUpperCase(),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
    }
}
