import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['packages', 'newPackageForm', 'unpackagedFulfillmentItems']

    addPackage(e) {
        e.preventDefault()

        fetch(e.currentTarget.href, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                if (this.hasNewPackageFormTarget) {
                    this.newPackageFormTarget.outerHTML = html
                } else {
                    var tempElement = document.createElement('div');
                    tempElement.innerHTML = html

                    this.packagesTarget.prepend(tempElement.firstChild)
                }

                this.unpackagedFulfillmentItemsTarget.innerHTML = ''
            })
    }

    refreshUnpackagedFulfillmentItems() {
        if (this.hasNewPackageFormTarget) {
            return
        }

        fetch(this.unpackagedFulfillmentItemsTarget.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                this.unpackagedFulfillmentItemsTarget.innerHTML = html
            })
    }
}
