import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "quantity",
        "price",
        "total"
    ]

    connect() {
        console.log("Line Item controller connected")
    }

    recalculateTotal(event) {
        console.log("Updating line item quantity")

        let quantity = this.quantityTarget.value.length ? parseInt(this.quantityTarget.value) : 0
        let price = this.priceTarget.value.length ? parseFloat(this.priceTarget.value) : 0.0
        let total = (quantity * price).toFixed(2)
        this.totalTarget.innerHTML = `$${total}`
    }
}
