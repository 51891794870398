import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "lineItems",
        'detailsContainer',
        'addProductModal',
        'couponCheckbox',
    ]

    connect() {
        console.log("Admin Order controller connected")
    }

    addProduct(event) {
        console.log("Adding product")

        const target = event.currentTarget
        this.copyProductElement(target)
        this.clearProductSearch()
        this.clearProductResults()
    }

    removeProduct(event) {
        console.log("Removing product")

        $(event.currentTarget).closest('.line_item').remove()
    }

    copyProductElement(productElement) {
        console.log("Copying product element")

        const $clonedLineItem = $(productElement).closest('.line_item').clone()
        const clonedItemID = $clonedLineItem.attr('id')
        const $existingLineItem = $(`#new-order-line-item-container #${clonedItemID}`)
        if ($existingLineItem.length) {
            $existingLineItem.remove()
        }

        $clonedLineItem.appendTo(this.lineItemsTarget)
    }

    onAddLineItemSuccess(event) {
        location.reload();
    }

    onAddLineItemError(event) {
        console.log('ON ADD LINE ITEM ERROR')
        console.log(event)
        const errorResponse = event.detail.xhr.responseText
        $("#add-product-alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>${errorResponse}</div>`)
    }

    clearProductSearch() {
        console.log("Clear product search")

        $("#order-product-search").val("")
    }

    clearProductResults() {
        console.log("Clear product results")

        $("#product-search-results").html('<div class="text-center"><h4>Search to add products</h4></div>')
    }

    // NOTE: Order coupon interface not currently being used, but left if needed
    toggleCoupon() {
        if ($(this.couponCheckboxTarget).is(":checked")) {
            this.applyCoupon()
        } else {
            this.removeCoupon()
        }
    }

    refreshDetails(html) {
        this.detailsContainerTarget.innerHTML = html
    }

    applyCoupon() {
        let url = `/admin/orders/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to add coupon to order</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.detailsContainerTarget.innerHTML = html
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from order</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }

    removeCoupon() {
        let url = `/admin/orders/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from order</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.detailsContainerTarget.innerHTML = html
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from order</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }
}
