import {Controller} from "@hotwired/stimulus"
// import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = ['content', 'lineItem', 'warehouse', 'couponCheckbox', 'passwordField', 'passwordModal', 'shipItLink']

    warehouseSelected(e) {
        const warehouseId = e.currentTarget.value

        this.warehouseTargets.forEach(target => {
            if (target.dataset.warehouseId == warehouseId) {
                target.classList.add('warehouse-selected')
            } else {
                target.classList.remove('warehouse-selected')
            }
        })

        this.lineItemTargets.forEach(target => {
            this.application.getControllerForElementAndIdentifier(target, 'fulfillment--line-item').warehouseSelected(warehouseId)
        })
    }

    warehouseSelectedProforma(e) {
        const warehouseId = e.currentTarget.value

        this.warehouseTargets.forEach(target => {
            if (target.dataset.warehouseId == warehouseId) {
                target.classList.add('warehouse-selected')
            } else {
                target.classList.remove('warehouse-selected')
            }
        })

        this.lineItemTargets.forEach(target => {
            this.application.getControllerForElementAndIdentifier(target, 'fulfillment--line-item').warehouseSelectedProforma(warehouseId)
        })
    }

    fulfillmentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.element.outerHTML = html
            })
    }

    shipItFulfillmentLinkClicked(e) {
        const fulfillmentElement = $(this.element);
        const shipment_require_password = fulfillmentElement.data("require-password");
        if (shipment_require_password === true) {
            e.preventDefault();
            e.stopImmediatePropagation();
            const quote_present = fulfillmentElement.find(`[data-quote-present="true"]`).length > 0;
            if (quote_present) {
                const quote_cost = parseFloat(fulfillmentElement.find('[data-quote-cost]').data("quote-cost"));
                const shipping_cost = parseFloat(fulfillmentElement.find('[data-shipping-cost]').data("shipping-cost"));
                const low_limit = quote_cost - (0.05 * quote_cost)
                const high_limit = quote_cost + (0.05 * quote_cost)
                if (shipping_cost < low_limit || shipping_cost > high_limit)  {
                    fulfillmentElement.find("#shipping-range").html(`The shipping cost is outside the 5% range of the quoted cost of $${quote_cost}. The shipping cost should be between $${low_limit.toFixed(2)} and $${high_limit.toFixed(2)}.`);
                    this.askForPassword(e);
                }
                else {
                    this.submitRequest(e)
                        .then(html => {
                            this.element.outerHTML = html
                        })
                }
            }
            else {
                $("#shipping-range").html("No quote present. Please enter the password.");
                this.askForPassword(e);
            }
        }
        else{
            this.submitRequest(e)
                .then(html => {
                    this.element.outerHTML = html
                })
        }
    }



    contentLinkClicked(e) {
        this.submitRequest(e)
            .then(html => {
                this.contentTarget.innerHTML = html
            })
    }

    cancelFulfillment(e) {
        this.submitRequest(e)
            .then(html => {
                this.refreshFulfillments()
                this.orderController.refreshDetails(html)
            })
    }

    get orderController() {
        let orderElement = document.getElementById(`order_${this.data.get("order-id")}`)
        return this.application.getControllerForElementAndIdentifier(orderElement, "order")
    }

    refreshFulfillments() {
        const event = document.createEvent('CustomEvent')
        event.initCustomEvent('fulfillment-transition-completed', true, true, null)
        this.element.dispatchEvent(event)
    }

    submitRequest(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        return fetch(e.currentTarget.href, {
            method: method.toUpperCase(),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
    }

    toggleCoupon() {
        if ($(this.couponCheckboxTarget).is(":checked")) {
            this.applyCoupon()
        } else {
            this.removeCoupon()
        }
    }

    applyCoupon() {
        let url = `/admin/orders/${this.data.get("order-id")}/fulfillments/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to add coupon to order</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.orderController.refreshDetails(html)
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from order</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }

    removeCoupon() {
        let url = `/admin/orders/${this.data.get("order-id")}/fulfillments/${this.data.get("id")}/coupons`

        return fetch(url, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from fulfillment</div>`)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
                return response.text()
            })
            .then(html => {
                this.orderController.refreshDetails(html)
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to remove coupon from fulfillment</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }

    askForPassword(event) {
        event.preventDefault();
        // Prompt for a password:
        this.passwordFieldTarget.value = '';
        $(this.passwordModalTarget)
            .find("#shipping-cost-password-errors").html("").end()
            .modal();
    }

    passwordChanged(event) {
        if (event.key === "Enter") {
            this.tryPassword(event);
        }
    }

    tryPassword(event) {
        event.preventDefault();
        const fulfillmentElement = $(this.element)
        const $btn = fulfillmentElement.find("#submit-password-button");
        $btn.button('loading');

        const link = this.shipItLinkTarget;

        const password = this.passwordFieldTarget.value;

        const formData = new URLSearchParams();
        formData.append('password', password); // Add the password to the request body

        return fetch(link.href, {
            method: 'POST',
            body: formData,
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/x-www-form-urlencoded",
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.text().then(html => {
                            $(this.passwordModalTarget).modal('hide');
                            this.element.outerHTML = html
                        });
                        $btn.button('reset');
                        break
                    case 400:
                        response.text().then(html => {
                            $btn.button('reset');
                            let existingErrorElement = $(this.passwordModalTarget).find("#shipping-cost-password-errors h5");
                            if (existingErrorElement.length) {
                                existingErrorElement.remove();
                            }
                            $(this.passwordModalTarget).find("#shipping-cost-password-errors").html("<h5 style='color: red'>Valid password required!</h5>");
                        });
                        break;
                }
            })
    }

}
