import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output"]

    submitRequest(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        return fetch(e.currentTarget.href, {
            method: method.toUpperCase(),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
    }

    generateQuotes(e) {
        this.submitRequest(e)
            .then(html => {
                this.element.outerHTML = html
            })
    }
}
