import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['onHandInput', 'adjustmentInput']

    connect() {
        this.adjustmentUpdated();
    }

    adjustmentUpdated() {
        this.onHandInputTarget.value = parseInt(this.onHandInputTarget.dataset.initialOnHandQuantity) + parseInt(this.adjustmentInputTarget.value)
    }

    onHandUpdated() {
        this.adjustmentInputTarget.value = parseInt(this.onHandInputTarget.value) - parseInt(this.onHandInputTarget.dataset.initialOnHandQuantity)
    }

    reset(e) {
        e.preventDefault();
        this.onHandInputTarget.value = this.onHandInputTarget.dataset.initialOnHandQuantity
        this.adjustmentInputTarget.value = 0
    }
}
