import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.parent = this.element.parentNode
    }

    linkClicked(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        fetch(e.currentTarget.href, {
            method: method,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
            })
    }

    submitForm(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        let form = e.currentTarget.form

        return fetch(form.action, {
            method: form.method,
            body: $(form).serialize(),
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/x-www-form-urlencoded",
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
                this.packageLineItemsUpdated()
            })
    }

    destroy(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        fetch(e.currentTarget.href, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
                this.packageLineItemsUpdated()
            })
    }

    cancelLineItem(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        e.currentTarget.closest('tr').remove();
    }

    destroyLineItem(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        e.currentTarget.previousElementSibling.querySelector('input').value = 'true'
        e.currentTarget.closest('tr').classList.add('d-none');
    }


    cancelNew(e) {
        e.preventDefault()
        this.element.parentNode.removeChild(this.element)
        this.packageLineItemsUpdated()
    }

    packageLineItemsUpdated() {
        const event = document.createEvent('CustomEvent')
        event.initCustomEvent('package-items-updated', true, true, null)
        this.parent.dispatchEvent(event)
    }
}
