import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        // filtering things:
        "archivedStatus",
        "toggleArchivedButton",
        "archivedForm",
        // changing things:
        "archiveForm",
        "toggleArchiveButton",
    ]

    connect() {
        if ($(this.archivedStatusTarget).val() == 'true') {
            $(this.toggleArchivedButtonTarget).html("Show Unarchived");
            $(this.toggleArchiveButtonTarget).html("Unarchive");
            $(this.archiveFormTarget).attr('action', $(this.archiveFormTarget).data('unarchive-url'));
        } else {
            $(this.toggleArchivedButtonTarget).html("Show Archived");
            $(this.toggleArchiveButtonTarget).html("Archive");
            $(this.archiveFormTarget).attr('action', $(this.archiveFormTarget).data('archive-url'));
        }
        console.log("DealerApps controller connected")
    }

    toggleArchived() {
        $(this.archivedStatusTarget).val($(this.archivedStatusTarget).val() == 'true' ? '' : 'true');
        this.archivedFormTarget.submit();
    }
}
