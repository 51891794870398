import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        document.querySelectorAll('.warehouse-info').forEach(function (el) {
            let truck = parseInt(el.dataset.truckCutoff);
            let parcel = parseInt(el.dataset.parcelCutoff);
            el.innerHTML = '';
            this.warehouseInfoHTML(el, truck, 'truck', 'truck');
            this.warehouseInfoHTML(el, parcel, 'parcel', 'cube');
        }, this);

        setInterval(() => {
            document.querySelectorAll('.warehouse-info').forEach(function (el) {
                let truck = parseInt(el.dataset.truckCutoff);
                let parcel = parseInt(el.dataset.parcelCutoff);
                el.innerHTML = '';
                this.warehouseInfoHTML(el, truck, 'truck', 'truck');
                this.warehouseInfoHTML(el, parcel, 'parcel', 'cube');
            }, this);
        }, 60000)
    }


    warehouseInfoHTML(element, cutoff, serviceType, icon) {
        let timestamp = Math.round(Date.now() / 1000);
        let redzone = 45 * 60;
        let hourmark = 60 * 60;
        let warehouse = element.dataset.warehouseId;
        let warehouseTimeZone = element.dataset.warehouseTimeZone;
        let warehouseTime = new Date(timestamp * 1000).toLocaleString("en-US", {timeZone: warehouseTimeZone});
        let cutoffWarehouseTime = new Date(cutoff * 1000).toLocaleString("en-US", {timeZone: warehouseTimeZone});
        if ((timestamp >= (cutoff - redzone)) && (timestamp <= cutoff)) {
            document.querySelectorAll('#order-index #uncommenced-orders [data-preferred-warehouse="' + warehouse + '"][data-service-type="' + serviceType + '"]').forEach(function (el) {
                el.classList.add('urgent');
                $(el).find('.pack-priority')[0].classList.remove('hidden');
                let counter = $(el).find('.preferred-warehouse .countdown')[0];
                counter.classList.remove('hidden');
                counter.innerHTML = Math.round((counter.dataset.warehouseCutoff - timestamp) / 60) + 'm';
            });
            element.innerHTML += ' <i class="fa fa-' + icon + ' fa-fw"></i><span class="counter">' + Math.round((cutoff - timestamp) / 60) + 'm</span>';
        } else if ((timestamp >= (cutoff - hourmark)) && (timestamp <= cutoff)) {
            element.innerHTML += ' <i class="fa fa-' + icon + ' fa-fw"></i><span class="counter">' + Math.round((cutoff - timestamp) / 60) + 'm</span>';
        } else if ((timestamp < cutoff) && (warehouseTime < cutoffWarehouseTime)) {
            //else if ((timestamp < cutoff)) {
            element.innerHTML += ' <i class="fa fa-' + icon + ' fa-fw"></i><span class="counter">' + Math.round((cutoff - timestamp) / 6000) + 'hr</span>';
        } else {
            element.innerHTML += ' <span class="text-muted"><i class="fa fa-' + icon + '"></i><i class="fa fa-moon fa-regular"></i></span>';
            document.querySelectorAll('#order-index #uncommenced-orders [data-preferred-warehouse="' + warehouse + '"][data-service-type="' + serviceType + '"]').forEach(function (el) {
                el.classList.remove('urgent');
                $(el).find('.pack-priority')[0].classList.add('hidden');
                $(el).find('.preferred-warehouse .countdown')[0].classList.add('hidden');
            });

        }
    }
}
