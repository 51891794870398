import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'packageItem', 'packagedItems', 'unpackagedItems', 'packagedItemsContainer',
        'unpackagedItemsContainer', 'deletionWarning'
    ]

    connect() {
        this.togglePackagedItemsWarning()
        this.toggleUnpackagedItems()
    }

    toggleItem(e) {
        e.preventDefault()
        let button = e.srcElement
        let item = button.closest('.package-item')
        let input = item.querySelector('input')

        if (item.dataset.packaged == 'true') {
            item.dataset.packaged = false
            button.classList.add('btn-success')
            button.classList.remove('btn-danger')
            button.innerHTML = 'Add'
            input.disabled = true
            this.unpackagedItemsContainerTarget.prepend(item)
        } else {
            item.dataset.packaged = true
            button.classList.remove('btn-success')
            button.classList.add('btn-danger')
            button.innerHTML = 'Remove'
            input.disabled = false
            this.packagedItemsContainerTarget.prepend(item)
        }

        // console.log(this.packagedItemsContainerTarget)
        this.togglePackagedItemsWarning()
        this.toggleUnpackagedItems()
    }

    togglePackagedItemsWarning() {
        if (this.packagedItemsContainerTarget.getElementsByClassName('package-item').length == 0) {
            this.deletionWarningTarget.style.display = '';
        } else {
            this.deletionWarningTarget.style.display = 'none';
        }
    }

    toggleUnpackagedItems() {
        if (this.unpackagedItemsContainerTarget.getElementsByClassName('package-item').length == 0) {
            this.unpackagedItemsTarget.style.display = 'none';
        } else {
            this.unpackagedItemsTarget.style.display = '';
        }
    }
}