import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['output']

    connect() {
        let delay = this.element.dataset.delayInSeconds || 0

        setTimeout(this.fetch.bind(this), delay * 1000);
    }

    fetch() {
        fetch(this.element.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                if (this.hasOutputTarget) {
                    this.outputTarget.innerHTML = html
                } else {
                    this.element.outerHTML = html
                }
            })
    }
}
