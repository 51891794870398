import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'form',
        'submit',
        'passwordModal',
        'passwordField',
    ]

    connect() {
        console.log("Proforma Invoice controller connected")
    }

    changePaymentMethod(event) {
        event.preventDefault();
        const m = $(event.currentTarget).data('payment-method');
        $(this.formTarget).find("#payment-token-method").val(m);
        const $tokenField = $(this.formTarget).find("#proforma_invoice_payment_token");
        if (m == 'payment_token') {
            $tokenField.attr('readonly', false)
                .val('');
        } else {
            $tokenField.attr('readonly', true)
                .val('')
                .attr('placeholder', event.currentTarget.innerHTML);
        }
    }

    askForPassword(event) {
        event.preventDefault();
        if ($(this.formTarget).find("#payment-token-method").val() == 'payment_token') {
            // No password needed; just submit:
            this.submitTarget.dispatchEvent(new CustomEvent("password"));
        } else {
            // Prompt for a password:
            this.passwordFieldTarget.value = '';
            $(this.passwordModalTarget)
                .find("#payment-token-errors").html("").end()
                .modal();
            $(this.passwordModalTarget).find("#proforma_invoice_password").focus();
        }
    }

    passwordChanged(event) {
        if (event.key === "Enter") {
            this.tryPassword(event);
        }
    }

    tryPassword(event) {
        event.preventDefault();
        const form = this.formTarget;

        return fetch(form.action, {
            method: form.method,
            body: $(form).serialize(),
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/x-www-form-urlencoded",
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.text().then(html => {
                            $(this.passwordModalTarget).modal('hide');
                            this.element.outerHTML = html;
                        });
                        break
                    case 400:
                        response.text().then(html => {
                            $(this.passwordModalTarget)
                                .find("#payment-token-proforma-password-errors").html($(html).find("#payment-token-errors").html()).end()
                                .find("#proforma_invoice_password").focus();
                        });
                        break;
                }
            });
    }
}
