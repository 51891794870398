import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        let options = {
            html: true,
            container: 'body',
            trigger: this.element.getAttribute('trigger') || 'hover',
            placement: this.element.getAttribute('placement') || 'top'
        }
        $(this.element).popover(options)
    }
}
