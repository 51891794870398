import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'form',
        'submit',
        'passwordModal',
        'passwordField',
    ]

    connect() {
        console.log("Adjustment Password controller connected")
    }

    askForPassword(event) {
        event.preventDefault();
        // Prompt for a password:
        this.passwordFieldTarget.value = '';
        $(this.passwordModalTarget)
            .find("#adjustment-password-errors").html("").end()
            .modal();
    }

    passwordChanged(event) {
        if (event.key === "Enter") {
            this.tryPassword(event);
        }
    }

    tryPassword(event) {
        event.preventDefault();

        const $btn = $("#submit-password-button");
        $btn.button('loading');

        const form = this.formTarget;

        return fetch(form.action, {
            method: form.method,
            body: $(form).serialize(),
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/x-www-form-urlencoded",
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.text().then(html => {
                            $(this.passwordModalTarget).modal('hide');
                            window.location.reload();
                        });
                        break
                    case 400:
                        response.text().then(html => {
                            $btn.button('reset');
                            let existingErrorElement = $(this.passwordModalTarget).find("#adjustment-password-password-errors h5");
                            if (existingErrorElement.length) {
                                existingErrorElement.remove();
                            }
                            $(this.passwordModalTarget).find("#adjustment-password-password-errors").html("<h5 style='color: red'>Valid password required!</h5>");
                        });
                        break;
                }
            });
    }
}
