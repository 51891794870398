import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "products",
        'addProductModal',
    ]

    connect() {
        console.log("Admin Location Inventory controller connected")
    }

    addProduct(event) {
        const target = event.currentTarget
        this.copyProductElement(target)
        this.clearProductSearch()
        this.clearProductResults()
    }

    removeProduct(event) {
        $(event.currentTarget).closest('.product').remove()
    }

    copyProductElement(productElement) {
        const $clonedLineItem = $(productElement).closest('.product').clone()
        const clonedItemID = $clonedLineItem.attr('id')
        const $existingLineItem = $(`#new-product-input-container #${clonedItemID}`)
        if ($existingLineItem.length) {
            $existingLineItem.remove()
        }

        $clonedLineItem.appendTo(this.productsTarget)
    }

    clearProductSearch() {
        $("#location-product-search").val("")
    }

    clearProductResults() {
        $("#product-search-results").html('<div class="text-center"><h4>Search to add products</h4></div>')
    }

    onAddProductSuccess(event) {
        location.reload();
    }

    onAddProductError(event) {
        console.log('ON ADD LINE ITEM ERROR')
        console.log(event)
        const errorResponse = event.detail.xhr.responseText
        $("#add-product-alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>${errorResponse}</div>`)
    }
}
